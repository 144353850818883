import { useState, useEffect } from 'react'
import { setComponents } from '@storyblok/react'
import { WebNextComponents } from '@izettle/public-site-components/dist/web-next'
import { Page } from '../content-types/page'
import { Layout } from '../content-types/layout'

const isClient = () => typeof window !== 'undefined'

const toKebabCase = (camelCase: string) => {
  return camelCase.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
}

let components: Record<string, any> = {}
const nextComponents = { Page, Layout }
const deprecatedComponents = { trustpilot: () => {} }
for (const [key, obj] of Object.entries({
  ...WebNextComponents,
  ...nextComponents,
  ...deprecatedComponents,
})) {
  components[toKebabCase(key)] = obj
}

export const useEditor = (story: any = null) => {
  const [state, setState] = useState<any>(story)

  setComponents(components)

  if (isClient()) {
    setTimeout(() => {
      // a second for storyblok-v2-latest.js to load

      if (window.StoryblokBridge) {
        const bridgeInstance = new window.StoryblokBridge({
          resolveRelations: ['global-reference.reference'],
          resolveLinks: 'url',
        })

        bridgeInstance.on(['change', 'published'], (event) => {
          if (!event?.slugChanged) {
            location.reload()
          }
        })

        bridgeInstance.on('input', ({ story: newStory }: any) => {
          if (newStory.content._uid === story.content._uid) {
            setState(newStory)
          }
        })

        bridgeInstance.on('enterEditmode', () => {
          const allBlocks = document.querySelectorAll(`[data-blok-uid]`)
          for (let i = 0; i < allBlocks.length; i++) {
            const blok: any = allBlocks[i]
            blok.offsetHeight < 5 && (blok.style['min-height'] = '5px')
            blok.classList.add('storyblok__outline')
          }
        })
      }
    }, 1000)
  }

  useEffect(() => {
    setState(story)
  }, [story])

  return state
}
