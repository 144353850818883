import Error from 'next/error'
import { GetServerSideProps, NextPage } from 'next'
import statusCodes from 'http-status-codes'

import { Layout } from '../../content-types/layout'
import { Page } from '../../content-types/page'
import { Seo } from '../../components/seo'
import { OttoIconProvider } from '@izettle/public-site-components/dist/web-next'
import { getOttoIcons } from '../../utils/get-otto-icons'
import { OttoIconCollection } from '@izettle/public-site-components/dist/components/contexts/otto-icon'

import { useEditor } from '../../hooks/use-editor'
import { storyblokInstance } from '../../services/storyblok'
import { generateHrefLangData, getAlternates } from '../../services/href-lang'
import pricingService from '../../services/pricing'
import { handleNotFoundDataFetch } from '../../utils/404-utils'
import { PageProps } from '../../typings/next-page'
import { getRequestIdFromHeader, log } from '../../lib/log'

const IndexPageWrapper: NextPage<PageProps> = ({
  error,
  page,
  layout,
  hrefLangAlternates,
  statusCode,
  ottoIcons,
}) => {
  if (error) {
    return <Error statusCode={error.statusCode} />
  }

  if (!page) {
    return null
  }

  const story = useEditor(page.data.story)
  const pageContent = story.content
  const layoutContent = layout ? layout.data.story.content : {}
  const locationData = {
    alternates: page.data.story.alternates,
    fullSlug: page.data.story.full_slug,
  }

  return (
    <>
      {pageContent.seo && (
        <Seo
          fullSlug={page.data.story.full_slug}
          alternates={hrefLangAlternates}
          seo={pageContent.seo}
          statusCode={statusCode}
        />
      )}
      <OttoIconProvider ottoIcons={ottoIcons as OttoIconCollection}>
        <Layout {...layoutContent} locationData={locationData}>
          <Page {...pageContent} />
        </Layout>
      </OttoIconProvider>
    </>
  )
}

export const getServerSideProps: GetServerSideProps<PageProps> = async ({
  query,
  res,
}) => {
  await generateHrefLangData()
  storyblokInstance.setQuery(query)

  const { locale } = query

  let page = null
  let layout = null
  const hrefLangAlternates = getAlternates(`/${locale}`)

  try {
    page = await storyblokInstance.get(`cdn/stories/${locale}`, {
      resolve_relations: 'global-reference.reference',
    })

    const { layout: uuid } = page.data.story.content
    if (uuid) {
      layout = await storyblokInstance.getLayout(uuid)
    }

    const ottoIcons: OttoIconCollection = await getOttoIcons([
      {
        uuid: page.data.story.uuid,
        timestamp: page.data.story.published_at,
        content: page.data.story.content,
      },
      {
        uuid,
        timestamp: layout?.data.story.published_at,
        content: layout?.data.story.content,
      },
    ])

    log.debug('web-next - [locale]/index.tsx', {
      reqId: getRequestIdFromHeader(res),
      uuid,
      hrefLangAlternates,
      statusCode: res.statusCode,
    })

    return {
      props: {
        page,
        layout,
        hrefLangAlternates,
        statusCode: res.statusCode,
        pricing: pricingService.data,
        ottoIcons,
      },
    }
  } catch (e: any) {
    if (typeof e === 'string') {
      e = JSON.parse(e)
    }
    const errorResponseStatus = e.status
    const statusCode = errorResponseStatus || statusCodes.INTERNAL_SERVER_ERROR
    res.statusCode = statusCode

    if (errorResponseStatus === statusCodes.NOT_FOUND) {
      log.debug('web-next - [locale]/index.tsx', {
        reqId: getRequestIdFromHeader(res),
        hrefLangAlternates,
        statusCode,
      })
      return await handleNotFoundDataFetch('cdn/stories/international/404')
    }

    log.error('web-next - [locale]/index.tsx', {
      reqId: getRequestIdFromHeader(res),
      hrefLangAlternates,
      statusCode,
    })

    return {
      props: {
        page,
        layout,
        statusCode,
        error: { statusCode },
      },
    }
  }
}

export default IndexPageWrapper
